
    import { Component, Vue } from 'vue-property-decorator';
    import { User as UserType } from '@/interfaces/user/User';
    import { fetchUser } from '@/api/User';
    import UserForm from '@/components/admin/users/UserForm.vue';
    import { updateUser } from '@/api/User';
    import auth from '@/store/auth/auth';

    @Component({
        components: {
            UserForm,
        },
    })
    export default class UserEdit extends Vue {
        private user: UserType | null = null;

        async fetchUser(): Promise<UserType> {
            let response = await fetchUser(this.$route.params.id);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();

                response = await fetchUser(this.$route.params.id);
            }

            const user = await response.parsedBody;

            if (!user) {
                throw new Error('Error while fetching User.');
            }

            return user;
        }

        async dispatchFetchUser() {
            this.user = await this.fetchUser();
        }

        /**
         * Do the actual user update.
         */
        async updateUser() {
            if (!this.user) {
                throw new Error('Error while updating user.');
            }

            const response = await updateUser(this.user);

            if (response) {
                // re-new the token to update the user information.
                await auth.dispatchRefreshToken();
            }

            if (!response || !(await response.parsedBody) || response.status !== 200) {
                throw new Error('Error while updating user.');
            }
        }

        /**
         * Dispatch user update. Use event object coming from submit event.
         **/
        async dispatchUpdateUser() {
            await this.updateUser();

            await this.$router.push({ name: 'userlist' });
            this.$buefy.toast.open('CRM-Account erfolgreich gespeichert.');
        }

        mounted() {
            this.dispatchFetchUser();
        }
    }
