
    import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
    import { User } from '@/interfaces/user/User';
    import { Auth } from '@/enums/user/groups/auth';
    import { Voice } from '@/enums/user/groups/voice';

    @Component
    export default class UserForm extends Vue {
        @Prop() user!: User;

        private authgroups: unknown;
        private voicegroups: unknown;

        private password = '';
        private passwordRepeat = '';
        private inputIsValid = false;

        @Emit('submit')
        submit() {
            if (this.isValidPW()) {
                // password is valid, so update it.
                this.user.password = this.password;
            }
        }

        checkInput() {
            const username = Boolean(
                this.user && this.user.username && this.user.username.length > 3,
            );
            const abbr = Boolean(this.user && this.user.abbr && this.user.abbr.length === 2);

            if (this.password || this.passwordRepeat) {
                this.inputIsValid = Boolean(username && abbr && this.isValidPW());
            } else {
                this.inputIsValid = username && abbr;
            }
        }

        isValidPW() {
            return (
                this.password &&
                this.passwordRepeat &&
                this.password === this.passwordRepeat &&
                this.password.length > 3
            );
        }

        mounted() {
            this.checkInput();
        }

        beforeCreate() {
            this.authgroups = Auth;
            this.voicegroups = Voice;
        }
    }
