import { del, get, HttpResponse, post } from '@/api/Api';
import { TokenType } from '@/enums/TokenType';
import { User } from '@/interfaces/user/User';

export async function fetchUsers(): Promise<HttpResponse<User[]>> {
    return await get<User[]>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/users/`,
        TokenType.ACCESS,
    );
}

export async function fetchUser(userId: string): Promise<HttpResponse<User>> {
    return await get<User>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/users/?id=${userId}`,
        TokenType.ACCESS,
    );
}

export async function deleteUser(user: User): Promise<HttpResponse<null>> {
    return await del<null>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/users/${user._id}/`,
        user,
        TokenType.ACCESS,
    );
}

export async function updateUser(user: User): Promise<HttpResponse<null>> {
    return await post<null>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/users/${user._id}/`,
        user,
        TokenType.ACCESS,
    );
}

export async function addUser(user: User): Promise<HttpResponse<null>> {
    return await post<null>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/users/`,
        user,
        TokenType.ACCESS,
    );
}
